/*
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-17 14:13:43
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-28 09:36:18
 */

/* eslint-disable */
import { wechatSign } from "@/api/index";
import { Toast } from "vant";

export default {
  data() {
    return {
      isGetLocation: false,
      scenicCode: "S001",
      scenicName: "石燕湖",
      keywords: "",
      testLocationInfo: {
        lnglat: { lng: 113091599, lat: 28006354 },
        angle: 0,
        speed: 5,
      },
      map: null,
      mapFinished: false,
      markerList: [],
      poiList: [],
      currentPoiList: {},
      isPlayVoice: false,
      voiceFile: null,
      soundObj: null,
      currentLocationInfo: [],
      time: null,
    };
  },
  methods: {
    wxConfig() {
      console.log("href:", window.location.href);
      const url = window.location.href;
      const that = this;
      const query = {
        appid: "wxbd024da6c6bab4e0",
        // secret: "91513f506e8517aff377aec2d64b7e24",
        url: url,
      };
      console.log("query:", query);
      wechatSign(query).then((res) => {
        const { data } = res;
        wx.config({
          debug: false,
          appId: query.appid,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.sha1,
          jsApiList: ["getLocation"],
        });
        wx.ready(function() {
          that.getLocationFromWx();
          // setInterval(() => {
          //   that.getLocationFromWx();
          // }, 2000);
        });
        wx.error(function(res) {
          that.isGetLocation = false;
          console.log("error:", res);
          Toast("服务器出了点小差..");
        });
      });
    },
    getLocationFromWx() {
      wx.getLocation({
        type: "gcj02",
        success: (res) => {
          console.log("this.mapFinished:", this.mapFinished);
          if (this.mapFinished) {
            const { latitude, longitude } = res;
            const latLng = this.map.isInScenic({
              lng: longitude * 1000000,
              lat: latitude * 1000000,
            });
            console.log("this.latLng:", latLng);
            if (latLng) {
              this.currentLocationInfo = [longitude * 1000000, latitude * 1000000];
              const gpsInfo = {
                lnglat: { lng: longitude * 1000000, lat: latitude * 1000000 },
                angle: 0,
                speed: 5,
              };
              this.map.setGpsInfo(gpsInfo, 1);
              this.map.getCarPos();
              this.isGetLocation = true;
              // this.map.setCenter([longitude * 1000000, latitude * 1000000]);
              console.log("gpsInfo:", gpsInfo);
              this.getLocationTodo();
            } else {
              Toast("当前位置不在景区范围内");
            }
          }
        },
        fail() {
          Toast("获取定位失败");
          this.isGetLocation = false;
          setTimeout(() => {
            this.wxConfig();
          }, 1000);
        },
      });
    },
    setTestLocation() {
      this.map.setGpsInfo(this.testLocationInfo, 1);
      this.map.getCarPos();
    },
    importJmapJS() {
      const script = document.createElement("script");
      const head = document.getElementsByTagName("head")[0];
      script.type = "text/javascript";
      script.defer = true;
      script.onload = script.onreadystatechange = () => {
        this.createJMap();
      };
      // script.src =
      //   "https://api.scelink.com/maps/js?version=V2.0&key=456ea4f4c605d995b26256115717bfe5";
      script.src =
        "https://api-map.huiqulx.com/maps/js?version=V2.0&key=456ea4f4c605d995b26256115717bfe5";
      head.appendChild(script);
    },
    createJMap() {
      this.map = new JMap.Map("mapContainer", {
        resizeEnable: true,
        scenicName: this.scenicName,
        centerIcon: 0,
        showBkg: true,
        alphaBkg: 0.1,
        vector: false, //矢量地图
        basicMapType: 12,
        zoom: 17,
      });
      this.map.setCarVisible(true);
      this.map.setShowElement([]);
      this.dealWithMapInfo();
    },
    dealWithMapInfo() {},
    addJmapMarker() {
      this.markerList = [];
      this.poiList.map((poiItem) => {
        const markerObj = new JMap.Marker({
          name: poiItem.name,
          lnglat: new JMap.LngLat(poiItem.lng, poiItem.lat),
          icon: this.currentPoiList.layerDefaultIcon || poiItem.imageUrl,
          showLevel: poiItem.showLevel,
          size: new JMap.Size(100, 68),
          offset: new JMap.Pixel(35, 60),
          iconC: this.currentPoiList.layerFocusIcon || poiItem.imageUrl,
          sizeC: new JMap.Size(100, 82),
          offsetC: new JMap.Pixel(36, 61),
          visible: true,
          poiObj: {
            type: "0",
            poiId: this.map.getScenicObjectId(),
            typePs: "",
            details: poiItem,
          },
          mapObj: this.map,
        });
        this.markerList.push(markerObj);
      });
      this.map.showCustomPoiList(this.markerList, false, true, true);
    },
    handlePlayVoice() {
      this.isPlayVoice = !this.isPlayVoice;
      if (this.voiceFile && this.voiceFile.length !== 0) {
        if (/.mp3$/.test(this.voiceFile) && this.isPlayVoice) {
          this.soundObj.playMP3(this.voiceFile);
        } else if (/.mp3$/.test(this.voiceFile) && !this.isPlayVoice) {
          this.soundObj.pause("TYPE_SOUND_SPOT");
        } else {
          if (this.isPlayVoice) {
            this.soundObj.play(this.voiceFile, "TYPE_SOUND_SPOT", false);
          } else {
            this.soundObj.pause("TYPE_SOUND_SPOT");
          }
        }
      }
    },
    throttle(fn) {
      let canRun = true; // 通过闭包保持一个标记
      return function() {
        if (!canRun) return;
        // 在函数开头判断标记是否为true，不为true则return
        canRun = false; // 立即设置为false
        setTimeout(() => {
          fn.apply(this, argument);
          // 最后在setTimeout执行完毕后再把标记设置为true表示可以执行下一次循环了，当定时器没有执行的时候标记为false
          canRun = true;
        }, 500);
      };
    },
    getLocationTodo() {},
  },
};
