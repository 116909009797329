/*
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-12 16:33:59
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-21 09:20:34
 */

import request from "@/utils/request";

export function wechatSign(params) {
  return request({
    url: "wechat/noauth/jssdk",
    method: "post",
    params,
    isWechatSign: true,
  });
}
export function loadMapLayerList(params) {
  return request({
    url: `travel/map_layer/noauth/_load_mapLayerList/${params}`,
    method: "post",
  });
}
export function queryRouters(params) {
  return request({
    url: `travel/tour_route/noauth/query_routes`,
    method: "get",
    params,
  });
}
export function loadPoints(params) {
  return request({
    url: `travel/tour_route/noauth/load_points`,
    method: "get",
    params,
  });
}

export function mapBykeywordsList(data) {
  return request({
    url: `travel/map_layer/noauth/_load_mapBykeywordsList`,
    method: "post",
    data,
  });
}

export function getLatelySpot(data) {
  return request({
    url: `travel/map_layer/noauth/getLatelySpot`,
    method: "post",
    data,
  });
}

export function getCardInfo(id) {
  return request({
    url: `travel/card_point/noauth/getCardInfo/${id}`,
    method: "get",
  });
}
