/*
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-12 16:29:30
 * @LastEditors: Trevor
 * @LastEditTime: 2021-03-15 10:23:37
 */
import axios from "axios";
import { Toast } from "vant";

const request = (options) => {
  options.method = options.method || "post";
  options.data = options.data || {};
  options.headers = options.headers || {
    "Content-Type": "application/json;charset=utf-8",
  };
  const baseURL = options.isWechatSign
    ? `https://aiapi.huiqulx.com/api/basic/`
    : `${process.env.VUE_APP_BASEURL}api/`;
  const httpInstance = axios.create({
    baseURL,
    timeout: 50000,
  });
  Toast.loading({
    duration: 0,
    forbidClick: true,
    message: "数据加载中..",
  });
  // request拦截器
  httpInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      // Do something with request error
      Toast.clear();
      Promise.reject(error);
    }
  );

  // respone拦截器
  httpInstance.interceptors.response.use(
    (response) => {
      const res = response.data;
      Toast.clear();
      if (res.code !== 0) {
        Toast(res.msg);
        return Promise.reject(res);
      }
      return response.data;
    },
    (error) => {
      Toast.clear();
      Toast(error.message);
      return Promise.reject(error);
    }
  );
  return httpInstance(options);
};

export default request;
